/**
 * Created by alexd on 4/9/2020.
 */
      

let domainName = "narvaro.eu";
let frontendDomainName = "https://admin.narvaro.eu";
let slackAuthUrl = "https://slack.com/oauth/v2/authorize?client_id=702487248113.1089270659779&scope=chat:write,incoming-webhook";
let STRIPE_PUBLIC_KEY = "pk_test_CDdrEYrRErlE9vhzu1yV24dX00ly9rvxxW";
let frontendResultsBaseUrl = "https://www." + domainName + "/frontend/results";
let numberOfExtraAnswerForVirtualVoxes = 5;
     
   
// let domainName = "teleportme.eu";
// let frontendDomainName = "https://admin.voxcollector.com";
// let slackAuthUrl = "https://slack.com/oauth/v2/authorize?client_id=702487248113.700041967187&scope=incoming-webhook,chat:write";
// let STRIPE_PUBLIC_KEY = "pk_live_xnighiD55HBwh7kqwsmnn2Q200eBGcKzuP";
// let frontendResultsBaseUrl = "https://www.voxcollector.com/results";
// let numberOfExtraAnswerForVirtualVoxes = 3100;
   
export const config = {
    DOMAIN_NAME: domainName,
    FRONTEND_DOMAIN_NAME: frontendDomainName,
    API_BASE_URL: "https://" + domainName + "/_api",
    FRONTEND_RESULTS_BASE_URL: frontendResultsBaseUrl,
    SLACK_REGISTER_WEBHOOK_URL: slackAuthUrl,
    STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY,

    PRICE_EXTRA_VOX_MONTHLY: 100,
    PRICE_PHYSICAL_VOX_SETUP: 250,
    PRICE_LICENSES : {1:200, 2 : 1000, 3:5000},
    PRICE_AUTO_PILOT_DAILY: 10,

    NUMBER_OF_EXTRA_ANSWER_FOR_VIRTUAL_VOXES: numberOfExtraAnswerForVirtualVoxes,
};

